import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"
import {
  faHome,
  faSearch,
  faSeedling,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons"
import "./header.scss"

import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => {
  return (
    <header className="header-container">
      <div className="in-container">
        <h2>{siteTitle}</h2>

        <div className="action-container">
          <a
            href="https://onurdayibasi.dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon aria-label="Digital Garden" icon={faSeedling} />
          </a>

          <a
            href="https://learnreactui.dev"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon aria-label="Marketplace" icon={faShoppingBasket} />
          </a>

          <a
            href="https://www.linkedin.com/in/onur-day%C4%B1ba%C5%9F%C4%B1-59919b1a/"
            aria-label="Linkedin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href="https://twitter.com/odayibasi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon aria-label="Twitter" icon={faTwitter} />
          </a>

          <Link to="/search">
            <FontAwesomeIcon aria-label="Home" icon={faSearch} />
          </Link>

          <Link to="/">
            <FontAwesomeIcon aria-label="Home" icon={faHome} />
          </Link>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
