import md5 from "react-native-md5";

export function genColorRandom(str) {
    let str_md5v = md5.str_md5(str);

    let hash = 0;
    for (let i = 0; i < str_md5v.length; i++) {
        hash = str_md5v.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}


let fnColorPalet = [
    "#393b79",
    "#5254a3",
    "#6b6ecf",
    "#9c9ede",
    "#637939",
    "#8ca252",
    "#b5cf6b",
    "#cedb9c",
    "#8c6d31",
    "#bd9e39",
    "#e7ba52",
    "#e7cb94",
    "#843c39",
    "#ad494a",
    "#d6616b",
    "#e7969c",
    "#7b4173",
    "#a55194",
    "#ce6dbd",
    "#de9ed6"
];


export function genColor() {

    let colorIndex = Math.floor(Math.random() * Math.floor(fnColorPalet.length));
    return fnColorPalet[colorIndex];
}