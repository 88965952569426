import PropTypes from "prop-types"
import React, { useEffect } from "react"
import "./outlink.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

const OutSideLink = ({ title, url, enabled }) => {
  const clsEnabled = enabled === true ? "enabled" : "disabled"
  return (
    <div className={clsEnabled}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    </div>
  )
}

export const StoryOutSideLink = ({
  title = "",
  url = "",
  enabled = true,
  returnStoryId,
}) => {
  const [read, setRead] = React.useState(false)

  useEffect(() => {
    if (window) {
      const urlParts = url.split("/")
      const urlLastPart =
        urlParts.length > 0 ? urlParts[urlParts.length - 1] : url
      const urlLastPartUpdated = urlLastPart + "="
      const result = localStorage.getItem(urlLastPartUpdated)
      if (result) setRead(true)
      else setRead(false)
    }
  }, [read, url])

  const clsEnabled = enabled === true ? "enabled" : "disabled"
  const urlParts = url.split("/")
  const urlLastPart = urlParts.length > 0 ? urlParts[urlParts.length - 1] : url

  let isReaded = read
  if (urlLastPart + "=" === returnStoryId) {
    isReaded = true
  }

  return (
    <div key={urlLastPart} id={urlLastPart} className={clsEnabled}>
      {isReaded && enabled && (
        <span className="status-read">
          <FontAwesomeIcon aria-label="Check" icon={faCheck} />
        </span>
      )}
      <a href={url} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    </div>
  )
}

OutSideLink.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  enabled: PropTypes.bool,
}

OutSideLink.defaultProps = {
  title: ``,
  url: ``,
  enabled: true,
}

export default OutSideLink
