/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import Layout from "./layout"
import SEO from "./seo"
import { Stats, Tag } from "./index"
import { StoryOutSideLink } from "./outlink"
import "./storyGroup.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons/faArrowCircleUp"
import { hasArrayElement } from "utils/array-util"

export const StoryGroup = ({ title, complex, stories, linkMap, eBooks }) => {
  const [index, setIndex] = React.useState(0)
  const [search, setSearch] = React.useState("")
  const [allRead, setAllRead] = React.useState(false)

  useEffect(
    index => {
      if (window) {
        let storyArr = []
        if (complex) {
          stories.forEach(el => (storyArr = storyArr.concat(el.storyPart)))
        } else {
          storyArr = storyArr.concat(stories)
        }

        const search = window.location.search
        if (typeof search === "string" && search.includes("?")) {
          const storyId = search.replace("?", "")
          localStorage.setItem(storyId, new Date().toISOString())
          setIndex(++index)
          setSearch(storyId)
        }

        const isAllRead =
          storyArr.length > 0 &&
          storyArr.every(el => {
            const { postId } = el
            if (el.enabled !== undefined && el.enabled === false) {
              return true
            }

            if (typeof postId === "string") {
              const postIdUpdated = postId + "="
              const result = localStorage.getItem(postIdUpdated)
              return result
            }

            return false
          })

        setAllRead(isAllRead)
      }
    },

    [index]
  )

  const generateLink = (story, index) => {
    return (
      <StoryOutSideLink
        key={index}
        title={story.title}
        url={`https://medium.com/@odayibasi/${story.postId}`}
        enabled={story.enabled}
        returnStoryId={search}
      />
    )
  }

  const generateTitle = (title, isH3) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {isH3 ? <h4> {title}</h4> : <h2> {title}</h2>}
        <span style={{ fontSize: 13, marginLeft: 3 }}>
          <Link to="/">
            <FontAwesomeIcon aria-label="Home" icon={faArrowCircleUp} />
          </Link>
        </span>
      </div>
    )
  }

  const conceptTags = Object.keys(linkMap) || []
  const techTagsVDOM = conceptTags.map((tag, index) => (
    <Tag key={index} title={tag} linkMap={linkMap} type={"Concept"} />
  ))

  const eBooksVDOM = eBooks?.map(eBook => (
    <a
      style={{ marginRight: "3px" }}
      target="_blank"
      rel="noreferrer"
      href={eBook.url}
    >
      <img
        style={{
          objectPosition: "center",
          objectFit: "cover",
          borderRadius: "0.25rem",
          width: "100%",
          height: "6rem",
          margin: "0",
        }}
        src={eBook.img}
        alt={eBook.title}
      />
    </a>
  ))

  let storiesVDOM = ""
  let storiesForStats = []
  if (complex) {
    const storiesVDOMForComplex = stories.map((storyPartObj, index) => {
      const { storyPart, title } = storyPartObj
      storiesForStats = [...storiesForStats, ...storyPart]
      const storiesPVDOM = storyPart.map((story, index) =>
        generateLink(story, index)
      )
      const classOfSubStory = index === 0 ? "" : "story-sub-group"

      return (
        <div className={classOfSubStory}>
          {generateTitle(title, true)}
          {storiesPVDOM}
        </div>
      )
    })

    storiesVDOM = (
      <>
        <h3>{title}</h3>
        {storiesVDOMForComplex}
      </>
    )
  } else {
    storiesForStats = [...stories]
    storiesVDOM = stories.map((story, index) => generateLink(story, index))
  }

  /**
   * Returns a random integer between min (inclusive) and max (inclusive).
   * The value is no lower than min (or the next integer greater than min
   * if min isn't an integer) and no greater than max (or the next integer
   * lower than max if max isn't an integer).
   * Using Math.round() will give you a non-uniform distribution!
   */
  const getRandomInt = (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const handleShareOnTwitter = () => {
    const hashtags = "egitim"
    const shareUrl = window.location.origin + window.location.pathname
    const text = `${title} yazılarını okumayı başarı ile tamamladınız, tebrikler. 👏 🎉`
    const url = `https://twitter.com/intent/tweet?text=${text}&url=${shareUrl}&hashtags=${hashtags}`
    window.open(url, "_blank")
  }

  const handleShareOnLinkedIn = () => {
    const shareUrl = window.location.origin + window.location.pathname
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`
    window.open(url, "_blank")
  }

  const avatarSrcURL = `https://d3jy31tj1gt845.cloudfront.net/medium/blobchars/ch${getRandomInt(
    1,
    10
  )}.png`

  const renderStatsPart = () => {
    return (
      <>
        <h2 className="sub-title">İstatistikler</h2>
        <div className="sub-container">
          <Stats stories={storiesForStats} />
        </div>
      </>
    )
  }

  const renderSharingPart = () => {
    return (
      <>
        <h2 className="sub-title">Paylaşım</h2>
        {allRead && (
          <div className="sub-container">
            <div>
              {title} yazılarını okumayı tamamladınız. Tebrikler 👏 🎉`.
            </div>
            <div className="sharing-content">
              <img
                className="avatar"
                src={avatarSrcURL}
                alt="Avatar Of Trainings"
              />
              <div className="sharing-buttons">
                <button
                  className="share-btn twitter"
                  onClick={handleShareOnTwitter}
                >
                  <FontAwesomeIcon aria-label="Twitter" icon={faTwitter} />
                  Twitter
                </button>
                <button
                  className="share-btn linkedin"
                  onClick={handleShareOnLinkedIn}
                >
                  <FontAwesomeIcon aria-label="LinkedIn" icon={faLinkedin} />
                  Linkedin
                </button>
              </div>
            </div>
          </div>
        )}
        {!allRead && (
          <div className="sub-container">
            Eğitim içerisindeki tüm yazıları okuduğunuzda avatarınız
            görüntülenecektir.
          </div>
        )}
      </>
    )
  }

  const renderEbooksPart = () => {
    return (
      <>
        {hasArrayElement(eBooks) && (
          <>
            <h2 className="sub-title">E-Kitaplar</h2>
            <div className="small-note">
              *Resimlerin üzerine tıklayarak ilgili e-kitaplara gidebilirsiniz
            </div>
            <div className="sub-container">{eBooksVDOM}</div>
          </>
        )}
      </>
    )
  }

  const renderTagsPart = () => {
    return (
      <>
        <h2 className="sub-title">Etiketler</h2>
        <>
          <div className="small-note">
            *Etiketlerin üzerine tıklayarak ilgili yazılara gidebilirsiniz
          </div>
          <div className="sub-container">{techTagsVDOM}</div>
        </>
      </>
    )
  }

  return (
    <Layout isVisibleBack={true}>
      <SEO title={title} imgUrl={avatarSrcURL} />

      {!complex && generateTitle(title)}

      <div className="main-stories-container">
        <div className="column">{storiesVDOM}</div>

        <div className="column stats-part">
          {renderStatsPart()}
          {renderEbooksPart()}
          {renderTagsPart()}
          {renderSharingPart()}
        </div>
      </div>
    </Layout>
  )
}

StoryGroup.propTypes = {
  title: PropTypes.string,
  complex: PropTypes.bool,
  stories: PropTypes.array,
  outSideLinks: PropTypes.array,
  linkMap: PropTypes.object,
}

StoryGroup.defaultProps = {
  title: ``,
  complex: false,
  stories: [],
  outSideLinks: [],
  linkMap: {},
}
