import React from "react"
import { faEye } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PropTypes from "prop-types"

import { ResponsiveCalendar } from "@nivo/calendar"
import { Link } from "gatsby"
import { useEffect, useState } from "react"
import OutSideLink from "./outlink"
import "./stats.scss"
import { InLink } from "./inlink"

const LOCAL_CONFIG = {
  statURL: "https://d3jy31tj1gt845.cloudfront.net/medium/story-stats.json?",
  mediumUserURL: "https://medium.com/@odayibasi/",
  detailStatURL: "/medium-istatistikleri-detay/",
}

export const Stats = ({ stories, all, mode, orderBy, activeGroup }) => {
  const [viewCount, setViewCount] = useState(0)
  const [groupStoriesStat, setGroupStoriesStat] = useState([])
  const [storiesStat, setStoriesStat] = useState([])
  const CONFIG = LOCAL_CONFIG

  useEffect(() => {
    // get data from GitHub api

    const time = new Date().getTime()
    fetch(`${CONFIG.statURL}${time}`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        const resultMap = new Map()
        resultData.forEach(el => resultMap.set(el.postId, el))

        let totalView = 0
        const groupStories = []

        if (all) {
          resultData.forEach(el => {
            const { views } = el
            totalView += views
            groupStories.push(el.postId)
          })
        } else {
          stories.forEach(el => {
            const story = resultMap.get(el.postId)
            if (story) {
              const { views } = story
              totalView += views
              groupStories.push(el.postId)
            }
          })
        }
        setViewCount(totalView)
        setStoriesStat(resultData)
        setGroupStoriesStat(groupStories)
      }) // set data for the number of stars

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [])

  if (mode === "icon-mode") {
    const strDetail = "Detay"

    return (
      <>
        <div className="stat-row">
          <div className="stat-item">
            <span className="view-count">
              <FontAwesomeIcon icon={faEye} />
            </span>
            {viewCount}
          </div>

          <div className="stat-item">
            <Link
              to={CONFIG.detailStatURL}
              state={{ stories: groupStoriesStat }}
            >
              {strDetail}({groupStoriesStat.length})
            </Link>
          </div>
          <div>
            <InLink to="/about/">Hakkımda</InLink>
          </div>
        </div>
      </>
    )
  } else if ("list-mode") {
    let orderedStats = storiesStat.filter(el => activeGroup.includes(el.postId))

    if (orderBy === "view-count") {
      orderedStats = orderedStats.sort((a, b) => {
        return b.views - a.views
      })
    } else if (orderBy === "love-count") {
      orderedStats = orderedStats.sort((a, b) => {
        return b.claps - a.claps
      })
    }

    const firstPublishedAtBucketMap = {}
    orderedStats.forEach(el => {
      const dt = el.firstPublishedAtBucket
      if (!firstPublishedAtBucketMap[dt]) {
        firstPublishedAtBucketMap[dt] = 1
      } else {
        firstPublishedAtBucketMap[dt] = firstPublishedAtBucketMap[dt] + 1
      }
    })

    const viewCountBuckets = [
      50000,
      20000,
      10000,
      5000,
      2000,
      1000,
      500,
      100,
      0,
    ]

    const storyStatDOM = []
    let firstPublishedAtBucket = ""
    let viewBucketIndex = 0

    let storyCounter = 0
    orderedStats.forEach((el, index) => {
      if (orderBy === "view-count") {
        if (viewCountBuckets[viewBucketIndex] > el.views) {
          storyStatDOM.push(
            <div className="row-title">
              {" "}
              Over {viewCountBuckets[viewBucketIndex]} views
              <span className="blog-count">({storyCounter})</span>
            </div>
          )
          viewBucketIndex++
          storyCounter = 0
        }
        storyCounter++
        storyStatDOM.push(
          <div className="row">
            <span className="title"> {index + 1}.</span>
            <span className="title">
              <OutSideLink
                title={el.title}
                url={`${CONFIG.mediumUserURL}${el.postId}`}
              />
            </span>
            <span className="date">({el.firstPublishedAtBucket}):</span>
            <span className="view">{el.views} view</span>
          </div>
        )
      } else {
        if (firstPublishedAtBucket !== el.firstPublishedAtBucket) {
          firstPublishedAtBucket = el.firstPublishedAtBucket
          storyStatDOM.push(
            <div className="row-title">
              {firstPublishedAtBucket}{" "}
              <span className="blog-count">
                ({firstPublishedAtBucketMap[firstPublishedAtBucket]})
              </span>
            </div>
          )
        }

        storyStatDOM.push(
          <div className="row">
            <span className="title"> {orderedStats.length - index}.</span>
            <span className="title">
              <OutSideLink
                title={el.title}
                url={`${CONFIG.mediumUserURL}${el.postId}`}
              />
            </span>
            <span className="date">({el.firstPublishedAtBucket}):</span>
            <span className="view">{el.views} view</span>
          </div>
        )
      }
    })

    const activityMap = {}
    orderedStats.forEach(el => {
      const dayP = new Date(el.firstPublishedAt).toISOString().slice(0, 10)
      if (!activityMap[dayP]) activityMap[dayP] = 0
      activityMap[dayP] = activityMap[dayP] + 1
    })

    const activityData = []
    for (let key in activityMap) {
      activityData.push({ day: key, value: activityMap[key] })
    }

    const activityStatDOM = (
      <ResponsiveCalendar
        data={activityData}
        from="2021-01-01"
        to="2021-12-31"
        emptyColor="#eeeeee"
        colors={["#61cdbb", "#97e3d5", "#e8c1a0", "#f47560"]}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        legends={[
          {
            anchor: "bottom-right",
            direction: "row",
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: "right-to-left",
          },
        ]}
      />
    )

    return (
      <div className="stat-container">
        {false && <div className="stat-activity">{activityStatDOM}</div>}
        <div className="stat-list">{storyStatDOM}</div>
      </div>
    )
  } else {
    return <></>
  }
}

Stats.propTypes = {
  stories: PropTypes.array,
  all: PropTypes.bool,
  mode: PropTypes.string,
  orderBy: PropTypes.string,
}

Stats.defaultProps = {
  stories: [],
  all: false,
  mode: "icon-mode",
  orderBy: "none",
}
