/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {graphql, useStaticQuery} from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({isVisibleBack, title, children}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    
    let siteTitle = data.site.siteMetadata.title;
    if (title) siteTitle = title;

    return (
        <>
          <Header siteTitle={siteTitle} isVisibleBack={isVisibleBack}/>
          <div
            style={{
              height: `calc(100vh - 70px)`,
              overflowY: 'auto'
            }}
          >
            <div
              style={{
                maxWidth: 960, 
                margin: `0 auto`,
                padding: `0px 1.0875rem 1.45rem`,
              }}>
              <main>{children}</main>
              <footer>
              </footer>
            </div>

          </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,

}

export default Layout
