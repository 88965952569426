import React from "react"
import { Link } from "gatsby"

export const InLink = props => {
  return (
    <div>
      <Link to={props.to}>{props.children}</Link>
    </div>
  )
}
